import React from "react";
import { Link } from "react-router-dom";
import { LogoInverted } from "../assets/icons";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { BiLogoGmail, BiLogoLinkedin } from "react-icons/bi";
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1500,
    })
  }, [])
  const { t } = useTranslation();
  return (
    <div className="w-full mx-auto bg-blue-950">
      <div className="flex flex-wrap md:w-10/12 w-full px-3 md:px-0 mx-auto justify-around items-start pt-4">
        <div className="md:w-4/12 w-full text-white text-normal p-3">
          <img data-aos="fade-right" className="w-10 mt-9" src={LogoInverted} />
          <h4 data-aos="fade-right" className="font-bold mt-4 text-xl">Abdurrahman & Co</h4>
          <address data-aos="fade-right" className="mt-3">
            {t('Footer Address')}
          </address>
          <div data-aos="fade-up" className="flex justify-start gap-3 mt-6">
            <a
              className="mt-3 text-blue-950 w-10 h-10 p-2.5 bg-slate-300 rounded-full inline-block hover:text-white"
              target="_blank"
              href="https://www.instagram.com/abdurrahman.lawfirm/"
            >
              <BsInstagram className="w-full h-full" />
            </a>
            <a
              className="mt-3 text-blue-950 w-10 h-10 p-2.5 bg-slate-300 rounded-full inline-block hover:text-white"
              target="_blank"
              href="mailto:abdurrahman.and.co@gmail.com"
            >
              <BiLogoGmail className="w-full h-full" />
            </a>
            <a
              className="mt-3 text-blue-950 w-10 h-10 p-2.5 bg-slate-300 rounded-full inline-block hover:text-white"
              target="_blank"
              href="https://wa.me/6282210100098"
            >
              <BsWhatsapp className="w-full h-full" />
            </a>
            <a
              className="mt-3 text-blue-950 w-10 h-10 p-2.5 bg-slate-300 rounded-full inline-block hover:text-white"
              target="_blank"
              href="https://www.linkedin.com/company/abdurrahman-co/"
            >
              <BiLogoLinkedin className="w-full h-full" />
            </a>
          </div>
        </div>
        <div className="md:flex-1 w-4/12 text-white flex flex-col p-2 mt-8 md:pl-10 pl-0">
          <h5 data-aos="fade-down" className="mt-1 font-bold text-xl">
            {t('Footer Our Page Title')}
          </h5>
          <Link data-aos="fade-right" className="mt-3 text-gray-300 hover:text-white" to="/">
            {t('Navbar Home')}
          </Link>
          <Link data-aos="fade-right" className="mt-3 text-gray-300 hover:text-white" to="/about">
            {t('Navbar About Us')}
          </Link>
          <Link data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            to="/service"
          >
            {t('Navbar Services')}
          </Link>
          <Link data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            to="/portofolio"
          >
            {t('Navbar Case Studies')}
          </Link>
          <Link data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            to="/blog"
          >
            Blog
          </Link>
          <Link data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            to="/contact"
          >
            {t('Navbar Contact Us')}
          </Link>
        </div>
        <div className="md:flex-1 w-4/12 text-white flex flex-col p-2 mt-8">
          <h5 data-aos="fade-down" className="mt-1 font-bold text-xl">
            {t('Footer Services')}
          </h5>
          <a data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            target="_blank"
            href="https://wa.me/6282210100098/?text=Halo%2C%20saya%20ingin%20bertanya%20terkait%20layanan%20di%20bidang%20Litigasi%20dari%20Abdurrahman%20%26%20Co."
          >
            {t('Footer Services Litigation')}
          </a>
          <a data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            target="_blank"
            href="https://wa.me/6282210100098/?text=Halo%2C%20saya%20ingin%20bertanya%20terkait%20layanan%20di%20bidang%20Non%20Litigasi%20dari%20Abdurrahman%20%26%20Co."
          >
            {t('Footer Services Non Litigation')}
          </a>
          <a data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            target="_blank"
            href="https://wa.me/6282210100098/?text=Halo%2C%20saya%20ingin%20bertanya%20terkait%20layanan%20di%20bidang%20Retainer%20dari%20Abdurrahman%20%26%20Co."
          >
            {t('Footer Services Retainer')}
          </a>
        </div>
        <div className="md:flex-1 w-4/12 text-white flex flex-col p-2 mt-8 md:pl-10 pl-0">
          <h5 data-aos="fade-down" className="mt-1 font-bold text-xl">
            {t('Navbar Contact Us')}
          </h5>
          <a data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            target="_blank"
            href="https://www.instagram.com/abdurrahman.lawfirm/"
          >
            Instagram
          </a>
          <a data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            target="_blank"
            href="mailto:abdurrahman.and.co@gmail.com"
          >
            Email
          </a>
          <a data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            target="_blank"
            href="https://wa.me/6282210100098"
          >
            Whatsapp
          </a>
          <a data-aos="fade-right"
            className="mt-3 text-gray-300 hover:text-white"
            target="_blank"
            href="https://www.linkedin.com/company/abdurrahman-co/"
          >
            Linkedin
          </a>
        </div>
      </div>
      <p className="text-center mt-8 text-white text-sm">
        © Abdurrahman & Co
      </p>
    </div>
  );
};

export default Footer;
