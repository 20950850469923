import React from 'react'
import { Link } from 'react-router-dom'

const PortoCard = ({id, title, type, pict, desc}) => {
    return (
        <Link to={`/portofolio/`+id} className='flex flex-col md:w-80 w-8/12 m-8 shadow-lg pb-8 bg-white' id={id}>
            <div className='w-full h-48'>
                <img className='object-cover w-full h-full' src={pict} />
            </div>
            <p className='font-bold mt-4 text-xl mx-4'>{title}</p>
            <p className='mx-4'>{type}</p>
            <p className='font-normal mt-4 mx-4 text-xs tracking-wide leading-5'>{desc.length > 200 ? desc.substring(0, 200) + '...' : desc}</p>
        </Link>
    )
}

export default PortoCard