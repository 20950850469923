import React, { useEffect, useState } from "react";
import PortoCard from "../components/portoCard";
import { Link } from "react-router-dom";
import { MdGavel } from "react-icons/md"
import { Heroimage } from "../assets/images";
import { onValue, ref as rtdbref } from "firebase/database";
import { FIREBASE_DB } from "../firebaseinit";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const Portofolio = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    onValue(rtdbref(FIREBASE_DB, "portodata"), (snapshot) => {
      const data = snapshot.val();
      const key = Object.keys(data)
      setData(data)
    });
    AOS.init({
      once: true,
      duration: 1500,
    })
  }, [])
  
  // const [data, setData] = useState([
  //   { id: 1, title: "Sekilas Tentang Batubara", type: "pertambangan", pict: Heroimage, desc: "Batubara merupakan salah satu komoditas tambang paling penting bagi Indonesia, dan penyumbang deviden terbesar bagi negara untuk diekspor. Di Indonesia kantong cadangan batubara menyebar di beberapa daerah di pulau sumatera hingga Kalimantan, diantaranya adalah jambi, Palembang, Kalimantan timur, Kalimantan Selatan serta papua." },
  //   { id: 2, title: "MINYAK SOLAR INDSUTRI", type: "peruhahaan", pict: Heroimage, desc: "Indonesia adalah salah satu negara produsen minyak terbesar di dunia, dengan melimpahnya cadangan minyak seharusnya indonesia bisa menjadi salah satu negara kaya seperti halnya negara-negara di  timur tengah , keuntungan inilah yang seharusnya kita maksimalkan, salah satu produk yang paling dibutuhkan adalah minyak solar industry." },
  //   { id: 3, title: "SENGKETA PERTANAHAN KELAPA SAWIT", type: "perusahaan", pict: Heroimage, desc: "Indonesia adalah negara agraris terbesar di Kawasan  asia Tenggara bahkan dunia, lahan yang melimpah serta subur dari sabang sampai marauke membuat Indonesia dibanjiri banyak komoditas pertanian dan perkebunan. Salah satu komoditas perkebunan terbesar yang di ekspor oleh Indonesia adalah kelapa sawit." },
  // ])
  const { t } = useTranslation();

  return (
    <div className='pt-16 overflow-x-hidden bg-slate-50 overflow-y-hidden'>
      {/* Title */}
      <div className="flex flex-col justify-center items-center w-10/12 mx-auto mt-10 overflow-y-hidden">
        <MdGavel data-aos="fade-down" className="w-32 h-32 text-blue-950 border-b-8 border-white" />
        <h2 data-aos="fade-right" className="text-5xl font-semibold text-blue-950 mt-6">
          {t('Page Portofolio Title 1')} <span className="font-bold text-amber-400">{t('Page Portofolio Title 2')}</span>
        </h2>
        <p data-aos="fade-right" className="mt-2 text-blue-950 text-opacity-80 text-center">
          {t('Page Portofolio Text')}
        </p>
      </div>

      {/* Project Show */}
      <div data-aos="fade-up" className='w-11/12 mx-auto'>
        <div className='flex flex-wrap mt-8 justify-around'>
          {data.map((element,i) => {
            return (
              <PortoCard key={i} id={i} title={element.title} type={element.tag} pict={element.img} desc={element.desc}  />
            )
          }
          )}
        </div>
      </div>
    </div>
  )
}

export default Portofolio;
