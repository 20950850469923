import React from "react";
import { FaHands } from "react-icons/fa"
import { MdOutlineRealEstateAgent } from "react-icons/md"
import { FaBuildingColumns } from "react-icons/fa6"
import { GiMineWagon } from "react-icons/gi"
import { BsCheckCircleFill } from "react-icons/bs"
import { Service1, Service2, Service3 } from "../assets/images"
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';


function Service() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1500,
    })
  }, [])

  const { t } = useTranslation();

  return (
    <div className="Service overflow-hidden">
      <div className="w-full py-16 bg-blue-950">
        {/* Title */}
        <div className="flex flex-col justify-center items-center w-10/12 mx-auto">
          <FaHands data-aos="fade-down" className="w-32 h-32 text-white border-b-8 border-white" />
          <h2 data-aos="fade-right" className="text-5xl font-semibold text-white mt-6">
            {t('Page Services Focus')} <span className="font-bold text-amber-400">AREA</span>
          </h2>
          <p data-aos="fade-right" className="mt-2 text-center text-white text-opacity-80">
            {t('Page Services Subtitle')}
          </p>
        </div>

        {/* Part of Practice */}
        <div className="flex my-24 justify-center gap-12 flex-wrap">

          <div data-aos="fade-right" className="md:w-3/12 w-full md:px-0 px-20 md:mt-0 mt-8 flex flex-col items-center">
            <FaBuildingColumns className="h-16 w-16 text-amber-400" />
            <p className="text-blue-50 font-semibold text-2xl mt-2">{t('Page Services Corporation Title')}</p>
            <p className="text-blue-50 font-normal mt-2 text-center">{t('Page Services Corporation')}</p>
          </div>

          <div data-aos="fade-down" className="md:w-3/12 w-full md:px-0 px-20 md:mt-0 mt-8 flex flex-col items-center">
            <GiMineWagon className="h-16 w-16 text-amber-400" />
            <p className="text-blue-50 font-semibold text-2xl mt-2">{t('Page Services Mining Title')}</p>
            <p className="text-blue-50 font-normal mt-2 text-center">{t('Page Services Mining')}</p>
          </div>

          <div data-aos="fade-left" className="md:w-3/12 w-full md:px-0 px-20 md:mt-0 mt-8 flex flex-col items-center">
            <MdOutlineRealEstateAgent className="h-16 w-16 text-amber-400" />
            <p className="text-blue-50 font-semibold text-2xl mt-2">{t('Page Services Construction Title')}</p>
            <p className="text-blue-50 font-normal mt-2 text-center">{t('Page Services Construction')}</p>
          </div>
        </div>
      </div>
      <div className="md:w-10/12 mx-auto my-16">

        {/* Litigasi */}
        <div className="flex flex-wrap items-center">
          <div data-aos="fade-right" className="md:w-4/12 md:order-1 w-full md:mb-0 mb-11">
            <img className="w-full" src={Service1} />
          </div>
          <div data-aos="fade-left" className="md:order-2 flex flex-col md:ml-16 ml-4 mr-4 flex-1">
            <h2 className="text-5xl font-semibold uppercase text-blue-950">{t('Page Services Litigation Title 1')}<span className="font-bold border-b-4 border-amber-400">{t('Page Services Litigation Title 2')}</span></h2>
            <p className="mt-4 w-10/12 text-blue-950 ">{t('Page Services Litigation Text')}</p>
            <div className="flex md:w-11/12">
              <ul className="flex-1 font-semibold text-blue-950 list-none mt-4">
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Pidana')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Perdata')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Tata Usaha Negara')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Niaga')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Pajak')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Agama')}</li>
              </ul>
              <ul className="flex-1 font-semibold text-blue-950 list-disc mt-4">
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation PKPU dan Kepailitan')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation PHI')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Militer')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation MK')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Litigation Sengketa Pilkada/Pemilu')}</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Non-Litigasi */}
        <div className="flex flex-wrap items-center mt-20">
          <div data-aos="fade-left" className="md:w-4/12 w-full md:order-2 order-1 md:mb-0 mb-11">
            <img className="w-full" src={Service2} />
          </div>
          <div data-aos="fade-right" className="flex flex-col md:ml-16 ml-4 mr-4 flex-1 order-1">
            <h2 className="text-5xl font-semibold uppercase text-blue-950">{t('Page Services Non Litigation Title 1')}<span className="font-bold border-b-4 border-amber-400">{t('Page Services Non Litigation Title 2')}</span></h2>
            <p className="mt-4 w-10/12 text-blue-950 ">{t('Page Services Non Litigation Text')}</p>
            <div className="flex md:w-11/12">
              <ul className="flex-1 font-semibold text-blue-950 list-none mt-4">
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Non Litigation Mediasi')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Non Litigation Konsultasi')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Non Litigation Negosiasi')}</li>
              </ul>
              <ul className="flex-1 font-semibold text-blue-950 list-none mt-4">
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Non Litigation Text Konsoliasi dan Penilaian Ahli')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Non Litigation Text Arbitrase')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Non Litigation Text Pengurusan Perizinan')}</li>
              </ul>
            </div>
          </div>
        </div>

        {/* Retainer */}
        <div className="flex flex-wrap items-center mt-20">
          <div data-aos="fade-right" className="md:w-4/12 w-full md:mb-0 mb-12">
            <img className="w-full" src={Service3} />
          </div>
          <div data-aos="fade-left" className="flex flex-col md:ml-16 ml-4 mr-4 flex-1">
            <h2 className="text-5xl font-semibold uppercase text-blue-950">{t('Page Services Retainer Title 1')}<span className="font-bold border-b-4 border-amber-400">{t('Page Services Retainer Title 2')}</span></h2>
            <p className="mt-4 w-10/12 text-blue-950 ">{t('Page Services Retainer Text')}</p>
            <div className="flex md:w-11/12">
              <ul className="flex-1 font-semibold text-blue-950 list-none mt-4">
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Legal Drafting')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Legal Opinion')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Legal Audit')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Review Contract')}</li>
              </ul>
              <ul className="flex-1 font-semibold text-blue-950 list-disc mt-4">
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Merger')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Akuisisi')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Spin Off & Split Off')}</li>
                <li className="flex items-center mt-2"><BsCheckCircleFill className="mr-2 w-8" />{t('Page Services Retainer Protect Perusahaan')}</li>
              </ul>
            </div>
          </div>
        </div>

      </div>


    </div>
  );
}

export default Service;
