import React from "react";
import { Heroimage, Aboutusimg, Alif, Agung, Cerry, Erdi, Trinov, } from "../assets/images";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Aboutus() {
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1500,
    })
  }, [])

  const { t } = useTranslation();

  return (
    <div className="bg-white overflow-x-hidden">

      {/* Solutions */}
      {/* <div className="py-16 bg-blue-950 ">
        <div data-aos="fade-up" className="md:mb-16 mb-6 text-center">
          <p className="mt-2 text-3xl font-extrabold leading-8 tracking-tight text-white  sm:text-4xl">
            {t('Page Aboutus Solution Title')}
          </p>
        </div>
        <div className="flex flex-wrap mb-12 ">
          <div data-aos="zoom-in" className="w-full p-8 md:w-1/2 md:border-r lg:w-1/3">
            <div className="flex items-center mb-6">
              <svg width="20" height="20" fill="currentColor" className="w-6 h-6 text-amber-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                </path>
              </svg>
              <div className="ml-4 text-xl text-amber-400 font-semibold">
                {t('Page Aboutus Litigation Title')}
              </div>
            </div>
            <p className="leading-loose text-white  text-md">
              {t('Page Aboutus Litigation')}
            </p>
          </div>
          <div data-aos="zoom-in" className="w-full p-8 md:w-1/2 lg:w-1/3 lg:border-r">
            <div className="flex items-center mb-6">
              <svg width="20" height="20" fill="currentColor" className="w-6 h-6 text-amber-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                </path>
              </svg>
              <div className="ml-4 text-xl text-amber-400 font-semibold">
                {t('Page Aboutus Non Litigation Title')}
              </div>
            </div>
            <p className="leading-loose text-white  text-md">
              {t('Page Aboutus Non Litigation')}
            </p>
          </div>
          <div data-aos="zoom-in" className="w-full p-8 md:w-1/2 md:border-r lg:w-1/3 lg:border-r-0">
            <div className="flex items-center mb-6">
              <svg width="20" height="20" fill="currentColor" className="w-6 h-6 text-amber-400" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                <path d="M491 1536l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7l-542 542q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832h-416v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z">
                </path>
              </svg>
              <div className="ml-4 text-xl text-amber-400 font-semibold">
                {t('Page Aboutus Retainer Title')}
              </div>
            </div>
            <p className="leading-loose text-white  text-md">
              {t('Page Aboutus Retainer')}
            </p>
          </div>
        </div>
        <div className="justify-center flex pt-2">

          <Link
            data-aos="fade-up" className="bg-amber-400 ml-10 px-8 py-2 text-xl rounded-xl text-white hover:text-blue-950 font-medium inline-block lg:-mt-0 md:-mt-0 hover:bg-amber-500 hover:shadow-md"
            to="/service"
          >
            {t('Page Aboutus Solutions Button')}
          </Link>
        </div>
      </div> */}


      {/* Teams */}
      {/* Team 1 */}
      <div className="bg-blue-950 pb-16">
      <div className="md:w-10/12 w-11/12 md:flex-row flex-col pt-16 mx-auto flex justify-center items-center">
        <div data-aos="flip-right" className="flex-1 md:order-1 flex flex-col justify-center">
          <img src={Alif} alt="Tentang Kami" className="object-cover md:w-9/12 w-8/12 items-center justify-center ml-16" />
          <p className="text-xl font-bold text-white tracking-wide mt-3 text-center">
            ALIF ABDURRAHMAN, S.H.,M.Kn.C.L.A.
          </p>
          <p className="text-xl font-bold text-amber-400 tracking-wide mt-3 text-center">
            Managing partner
          </p>
        </div>
        <div data-aos="fade-right" className="flex-1 md:order-2 md:mt-0 mt-11 lg:mr-10 md:mr-10 mr-0" >
          <p className="text-lg text-white tracking-wide text-justify">
            {t('Page Aboutus Alif 1')}
            <br /> <br />
            {t('Page Aboutus Alif 2')}<br />
            {t('Page Aboutus Alif 3')}<br />
            {t('Page Aboutus Alif 4')}<br />
            {t('Page Aboutus Alif 5')}<br />
            {t('Page Aboutus Alif 6')}<br />
            {t('Page Aboutus Alif 7')}<br />
          </p>
        </div>
      </div>
      </div>
      {/* Team 2 */}
      <div className="md:w-10/12 w-11/12 md:flex-row flex-col pt-16 mx-auto flex justify-center items-center">
        <div data-aos="flip-left" className=" flex-col flex-1 md:order-2 flex justify-center">
          <img src={Cerry} alt="Tentang Kami" className="object-cover md:w-9/12 w-8/12 ml-20" />
          <p className="text-xl font-bold text-blue-950 tracking-wide mt-0 text-center">
            CERRY ABDULLAH, S.H.
          </p>
          <p className="text-xl font-bold text-amber-400 tracking-wide mt-0 text-center">
            Partner
          </p>
        </div>
        <div data-aos="fade-left" className="flex-1 md:order-1 md:mt-0">
          <p className="text-lg text-blue-950 tracking-wide mt-5  lg:ml-20 md:ml-20 ml-0 text-justify">
            {t('Page Aboutus Cerry 1')}
          </p>
        </div>
      </div>

      {/* Team 3 */}
      <div className="md:w-10/12 w-11/12 md:flex-row flex-col pt-16 mx-auto flex justify-center items-center">
        <div data-aos="flip-right" className=" flex-col flex-1 md:order-1 flex justify-center">
          <img src={Erdi} alt="Tentang Kami" className="object-cover md:w-9/12 w-8/12 ml-12" />
          <p className="text-xl font-bold text-blue-950 tracking-wide mt-0 text-center">
            ERDI PURNOMO, S.H.
          </p>
          <p className="text-xl font-bold text-amber-400 tracking-wide mt-0 text-center">
            Associate
          </p>
        </div>
        <div data-aos="fade-right" className="flex-1 md:order-2 md:mt-0">
          <p className="text-lg text-blue-950 tracking-wide lg:mr-20 md:mr-20 mr-0 text-justify">
            {t('Page Aboutus Erdi 1')}
          </p>
        </div>
      </div>

      {/* Team 4 */}
      <div className="md:w-10/12 w-11/12 md:flex-row flex-col pt-16 mx-auto flex justify-center items-center">
        <div data-aos="flip-left" className=" flex-col flex-1 md:order-2 flex justify-center">
          <img src={Agung} alt="Tentang Kami" className="object-cover md:w-9/12 w-8/12 ml-28" />
          <p className="text-xl font-bold text-blue-950 tracking-wide mt-5 text-center">
            AGUNG PRASETYA ADI, S.H.
          </p>
          <p className="text-xl font-bold text-amber-400 tracking-wide mt-0 text-center">
            Junior associate
          </p>
        </div>
        <div data-aos="fade-left" className="flex-1  md:order-1 md:mt-0">
          <p className="text-lg text-blue-950 tracking-wide lg:ml-20 md:ml-20 ml-0 text-justify">
            {t('Page Aboutus Agung 1')}
          </p>
        </div>
      </div>

      {/* Team 5 */}
      <div className="md:w-10/12 w-11/12 md:flex-row flex-col pt-16 mx-auto flex justify-center items-center pb-24">
        <div data-aos="flip-right" className="flex-1 flex-col md:order-1 flex justify-center">
          <img src={Trinov} alt="Tentang Kami" className="object-cover md:w-9/12 w-8/12 ml-10" />
          <p className="text-xl font-bold text-blue-950 tracking-wide mt-3 text-center">
            TRI NOVIAN BURMAN, S.H.
          </p>
          <p className="text-xl font-bold text-amber-400 tracking-wide mt-0 text-center">
            Junior associate
          </p>
        </div>
        <div data-aos="fade-right" className="flex-1 md:order-2 md:mt-0">
          <p className="text-lg text-blue-950 tracking-wide text-justify lg:mr-20 md:mr-20 mr-0">
            {t('Page Aboutus Trinov 1')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
