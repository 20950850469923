import React,{useEffect, useState} from "react";
import {AiOutlineSearch} from "react-icons/ai"
import { BlogCard } from "../components";
import { onValue, ref as rtdbref } from "firebase/database";
import { FIREBASE_STORE, FIREBASE_DB } from "../firebaseinit";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from 'react-i18next';

const Blog = () => {
  const [dataArt,setDataArt] = useState([])
  const [keyArticle, setKeyArticle] = useState([])
  const [queryText, setQueryText] = useState('')
  const [filteredKey, setFilteredKey] = useState([])
  const [tagKey, setTagKey] = useState('all')
  useEffect(() => { 
    onValue(rtdbref(FIREBASE_DB, "data"), (snapshot) => {
      const data = snapshot.val();
      const key = Object.keys(data)
      setKeyArticle(key)
      setDataArt(data)
      setFilteredKey(key)
    });
  }, [])

  const handleQuery = (e)=>{
    const query = e.currentTarget.value;
    setQueryText(query);
    const res = keyArticle.filter(key=>{
      return dataArt[key].title.toLowerCase().includes(query.toLowerCase())
  })
    setFilteredKey(res)
  }

  const handleTag = (tagName) =>{
    setTagKey(tagName)
    if(tagName.toLowerCase() == 'all'){
      setFilteredKey(keyArticle)
    }
    else{
      const res = keyArticle.filter(key=>{
        return dataArt[key].tag.toLowerCase().includes(tagName.toLowerCase())
      })
      setFilteredKey(res)
    }
  }

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1500,
    })
  }, [])

  const { t } = useTranslation();

  return (
    <div className="w-full mx-auto bg-blue-100 overflow-x-hidden">
      {/* Title */}
      <div className="w-10/12 mx-auto pt-20 overflow-hidden">
        <h1 data-aos="fade-right" className="text-5xl font-semibold text-blue-950">{t('Page Blog Title 1')}<span className="font-bold text-amber-400">{t('Page Blog Title 2')}</span></h1>
        <p data-aos="fade-right" className="mt-2 text-blue-950">
        {t('Page Blog Text')}
        </p>
      </div>

      {/* Search Feature */}
      <div className="w-10/12 mx-auto flex-col items-center justify-center mt-12">
        <div data-aos="fade-left" className="w-full flex justify-center">
          <form className="w-6/12">
            <input value={queryText} onChange={(e)=>handleQuery(e)} className="w-full px-4 py-3" type="text" placeholder={t('Page Blog Search')} />
          </form>
          <button className="bg-blue-950 text-white px-4 text-3xl font-semibold"><AiOutlineSearch className="text-white" /></button>
        </div>
        <div data-aos="fade-left" className="flex gap-4 justify-center my-6 flex-wrap">
          <span onClick={()=>handleTag('All')} className={`px-6 text-center cursor-pointer ${tagKey == "All" ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} py-1.5 border-2  w-36 font-semibold  text-sm`} >{t('Page Blog Category All')}</span>
          <span onClick={()=>handleTag('Hukum')} className={`px-6 text-center cursor-pointer ${tagKey == "Hukum" ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} py-1.5 border-2  w-36 font-semibold  text-sm`} >{t('Page Blog Category Hukum')}</span>
          <span onClick={()=>handleTag('Perusahaan')} className={`px-6 text-center cursor-pointer ${tagKey == "Perusahaan" ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} py-1.5 border-2 w-36 font-semibold text-sm`} >{t('Page Blog Category Perusahaan')}</span>
          <span onClick={()=>handleTag('Pertambangan')} className={`px-6 text-center cursor-pointer ${tagKey == "Pertambangan" ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} py-1.5 border-2 w-36 font-semibold text-sm`} >{t('Page Blog Category Pertambangan')}</span>
          <span onClick={()=>handleTag('Konstruksi')} className={`px-6 text-center cursor-pointer ${tagKey == "Konstruksi" ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} py-1.5 border-2 w-36 font-semibold text-sm`} >{t('Page Blog Category Konstruksi')}</span>
        </div>
      </div>

      {/* ARTICLES CHOICE */}
      <div data-aos="fade-up" className="w-11/12 mx-auto py-16 flex flex-wrap md:justify-between justify-center">

      {filteredKey != [] ? 
      filteredKey.map(key=>{
        return(<BlogCard key={key} title={dataArt[key].title} tag={dataArt[key].tag} imgArt={dataArt[key].image} desc={dataArt[key].desc} dateTime={key} />)
      })
      : null }
      </div>
      
    </div>
  );
};

export default Blog;
