// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getStorage } from "firebase/storage"
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyCuqyKoyQHgggfuKGKRr7VPU8aeZ5u8BA8",
  authDomain: "abdurrahmanproject.firebaseapp.com",
  databaseURL: "https://abdurrahmanproject-default-rtdb.firebaseio.com",
  projectId: "abdurrahmanproject",
  storageBucket: "abdurrahmanproject.appspot.com",
  messagingSenderId: "780427677783",
  appId: "1:780427677783:web:e5e5bbd40b77a33a679312",
  measurementId: "G-T3JFT6SB6M"
};

// Initialize Firebase
export const FIREBASE_APP = initializeApp(firebaseConfig);
export const FIREBASE_AUTH = getAuth(FIREBASE_APP);
export const FIREBASE_STORE = getStorage(FIREBASE_APP);
export const FIREBASE_DB = getDatabase(FIREBASE_APP);