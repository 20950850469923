import React,{useEffect, useState} from "react";
import {AiOutlineSearch} from "react-icons/ai"
import { onValue, ref as rtdbref, set } from "firebase/database";
import { FIREBASE_STORE, FIREBASE_DB } from "../firebaseinit";

import BlogEdit from "../components/blogEdit";

const Admin = () => {
  const [dataArt,setDataArt] = useState([])
  const [keyArticle, setKeyArticle] = useState([])
  const [query, setQuery] = useState('')
  const [keyResults, setKeyResults] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [queryTag, setQueryTag] = useState('all')
  useEffect(() => {
    onValue(rtdbref(FIREBASE_DB, "data"), (snapshot) => {
      const data = snapshot.val();
      const key = Object.keys(data)
      const tagList = []
      key.map(list=>{
        tagList.push(data[list].tag)
      })
      setTagList(tagList)
      setKeyResults(key)
      setKeyArticle(key)
      setDataArt(data)
    });
  }, [])

  const handleSearch = e =>{
    const querySearch = e.currentTarget.value;
    setQuery(e.currentTarget.value)

    const filteredResults = keyArticle.filter(item=>{
      return dataArt[item].title.toLowerCase().includes(querySearch.toLowerCase())
    })
    setKeyResults(filteredResults)
  }

  const handleTag = e =>{
    const tagQ = e.currentTarget.id
    setQueryTag(tagQ)
    if(tagQ != 'all'){
      const filteredResults = keyArticle.filter(item=>{
        return dataArt[item].tag.toLowerCase().includes(tagQ.toLowerCase())
      })
      setKeyResults(filteredResults)
      console.log(filteredResults)
    }
    else{
      setKeyResults(keyArticle)
      console.log(keyArticle)
    }
  }
  
  return (
    <div className="w-full mx-auto bg-blue-100 pt-16">

      {/* Search Feature */}
      <div className="w-10/12 mx-auto flex-col items-center justify-center mt-12">
        <div className="w-full flex justify-center">
          <form className="w-6/12">
            <input className="w-full px-4 py-3" value={query} onChange={handleSearch} type="text" placeholder="Cari Bantuan Hukum" />
          </form>
          <button className="bg-blue-950 text-white px-4 text-3xl font-semibold"><AiOutlineSearch className="text-white" /></button>
        </div>
        <div className="flex gap-4 justify-center my-6 flex-wrap">
          <div onClick={(e)=>handleTag(e)} id="all" className={`px-6 py-1.5 cursor-pointer ${queryTag == 'all' ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} border-2 w-28 font-semibold  text-sm flex justify-center`} ><span>All</span></div>
          <div onClick={(e)=>handleTag(e)} id="hukum" className={`px-6 py-1.5 cursor-pointer ${queryTag == 'hukum' ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} border-2  w-28 font-semibold text-sm flex justify-center`} ><span>Hukum</span></div>
          <div onClick={(e)=>handleTag(e)} id="bangunan" className={`px-6 py-1.5 cursor-pointer ${queryTag == 'bangunan' ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} border-2 border-blue-950 w-28 font-semibold text-blue-950 text-sm flex justify-center`} ><span>Bangunan</span></div>
          <div onClick={(e)=>handleTag(e)} id="perusahaan" className={`px-6 py-1.5 cursor-pointer ${queryTag == 'perusahaan' ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} border-2 border-blue-950 w-28 font-semibold text-blue-950 text-sm flex justify-center`} ><span>Perusahaan</span></div>
          <div onClick={(e)=>handleTag(e)} id="konstruksi" className={`px-6 py-1.5 cursor-pointer ${queryTag == 'konstruksi' ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} border-2 border-blue-950 w-28 font-semibold text-blue-950 text-sm flex justify-center`} ><span>Konstruksi</span></div>
          <div onClick={(e)=>handleTag(e)} id="pertambangan" className={`px-6 py-1.5 cursor-pointer ${queryTag == 'pertambangan' ? "text-amber-400 bg-blue-950" : "border-blue-950 text-blue-950"} border-2 border-blue-950 w-28 font-semibold text-blue-950 text-sm flex justify-center`} ><span>Pertambangan</span></div>
        </div>
      </div>

      {/* ARTICLES CHOICE */}
      <div className="w-10/12 mx-auto py-16 flex flex-wrap justify-between">

      {keyResults != [] ? 
      keyResults.map(key=>{
        return(<BlogEdit key={key} title={dataArt[key].title} tag={dataArt[key].tag} imgArt={dataArt[key].image} desc={dataArt[key].desc} dateTime={key} />)
      })
      : null }
      </div>
      
    </div>
  );
};

export default Admin;
