import React,{useEffect, useState} from 'react'
import { Link } from 'react-router-dom'


const BlogCard = ({imgArt, title, tag, desc, dateTime}) => {
    const [dateString, setDateString] = useState('')
    useEffect(()=>{
        const date = new Date(parseInt(dateTime))
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        const formattedDate = date.toLocaleDateString(undefined, options);
        setDateString(formattedDate)
    },[])

    const truncateText = (text, maxLength) => (text.length > maxLength ? `${text.slice(0, maxLength - 3)}...` : text);
 
  return (
    <div className="w-80 my-4 bg-white border-2 border-blue-900 border-opacity-10">
        <Link to={`/article/`+ dateTime}>
          <div className="w-full h-48">
            <img src={imgArt} className="object-cover h-full w-full" />
          </div>
          <div>
            <div className="-mt-1 ml-3">
              <span className="px-1.5 bg-blue-900 text-white py-1 text-xs">#{tag}</span></div>
            <h4 className="text-lg mx-3 font-semibold text-blue-950 mt-4">{title}</h4>
            <p className="text-sm mx-3 mt-1">{truncateText(desc, 100)}</p>
            <p className="text-xs m-4">{dateString}</p>
          </div>
        </Link>
      </div>
  )
}

export default BlogCard