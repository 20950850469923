import React from "react";
import { Link } from "react-router-dom";
import {
  Heroimage,
  Aboutusimg,
  Person1,
  Person2,
  Articlephoto1,
  Miniagung,
  Minialif,
  Minicerry,
  Minierdi,
  Minitrinov,
} from "../assets/images";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { BiLogoGmail, BiLogoLinkedin } from "react-icons/bi";
import { BlogCard } from "../components";
import { onValue, ref as rtdbref } from "firebase/database";
import { FIREBASE_STORE, FIREBASE_DB } from "../firebaseinit";
import { useTranslation } from 'react-i18next';

function Home() {
  const [dataArt, setDataArt] = useState([])
  const [keyArticle, setKeyArticle] = useState([])
  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1500,
    });
    onValue(rtdbref(FIREBASE_DB, "data"), (snapshot) => {
      const data = snapshot.val();
      const key = Object.keys(data);
      setKeyArticle(key.slice(-3));
      setDataArt(data);
    });
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bg-white overflow-x-hidden">
      {/* Header Desktop */}
      <div
        style={{
          backgroundImage: `url(${Heroimage})`,
          backgroundPosition: "center",
          backgroundAttachment: "scroll",
          backgroundSize: "cover",
        }}
        className="md:w-full md:flex-row flex-col w-full mx-auto md:flex justify-center items-center py-48 hidden"
      >
        <div className="flex-1 md:order-1 order-2 flex justify-center">

        </div>
        <div className="flex-1 md:order-1 order-2 flex justify-center -ml-40 pr-5 pl-5">

          <div className="w-3/3 ">
            <h1 data-aos="fade-right" className="text-5xl font-bold text-white tracking-wide ml-40  ">
              Abdurrahman & Co
            </h1>
            <h2 data-aos="fade-right" className="ml-24 text-2xl font-semibold mt-3 text-white tracking-wide text-center  mb-40">
              Honiste Vivere
            </h2>
          </div>
        </div>
      </div>

      {/* Header Small */}
      <div
        style={{
          backgroundImage: `url(${Heroimage})`,
          backgroundPosition: "center",
          backgroundAttachment: "scroll",
          backgroundSize: "390%",
        }}
        className="md:hidden block"
      >
        <div className="container pt-36 pb-36 s:w-screen w-4/5 mx-auto flex justify-center">
          <div className="flex-1">
            <h1 className="text-3xl font-black text-white tracking-wide text-end -mr-7">
              Abdurrahman & Co
            </h1>
            <h2 className="text-xl mt-3 text-white tracking-wide text-end mb-32 -mr-7">
              Honiste Vivere
            </h2>
          </div>
        </div>
      </div>

      {/* Cek i18n */}
      {/* About Us */}
      <div className="bg-slate-200 py-16">
        <h1 data-aos="fade-down" className=" text-3xl font-bold text-blue-950 text-center mb-5 md:ml-32 md:mr-32 ml-5 mr-5 ">
          Sekapur Sirih
        </h1>
        <h1 data-aos="fade-up" className=" text-xl text-blue-950 text-center md:ml-32 md:mr-32 ml-5 mr-5 ">
          {t('Page Home Aboutus 1')}
        </h1>
        <h1 data-aos="fade-up" className=" text-xl text-blue-950 text-center md:ml-32 md:mr-32 ml-5 mr-5 mt-5">
          {t('Page Home Aboutus 2')}
        </h1>
      </div>

      {/* Achievements */}
      <div className="w-full bg-blue-950">
        <div className="w-full md:flex-row flex-col mx-auto flex justify-center items-center">
          <div className="flex-1 md:order-1 md:mt-0 border-white items-center px-10 ml-0 lg:border-r-4 md:border-r-4 md:pr-4 lg:pr-16 md:border-b-0 border-b-4">
            <p data-aos="fade-right" className="text-6xl font-bold text-center text-blue-50 tracking-wide mt-8 ">
              37+ <br />
            </p>
            <p data-aos="fade-right" className="text-2xl mt-2 font-normal text-center text-blue-50 tracking-wide mb-8 ">
            {t('Page Home Clients Corporate')}
            </p>
          </div>
          <div className="flex-1 md:order-1 md:mt-0 md:px-4 ">
            <p data-aos="fade-down" className="text-6xl font-bold text-center text-blue-50 tracking-wide mt-8">
              57+ <br />
            </p>
            <p data-aos="fade-down" className="text-2xl mt-2 font-normal text-center text-blue-50 tracking-wide mb-8">
            {t('Page Home Clients')}
            </p>
          </div>
          <div className="flex-1 md:order-1 md:mt-0 border-white lg:border-l-4 md:border-l-4 md:pl-4 lg:r-16 md:border-t-0 border-t-4 px-14">
            <p data-aos="fade-left" className="text-6xl font-bold text-center text-blue-50 tracking-wide mt-8 ">
              25+ <br />
            </p>
            <p data-aos="fade-left" className="text-2xl mt-2 font-normal text-center text-blue-50 tracking-wide mb-8 ">
            {t('Page Home Services')}
            </p>
          </div>
        </div>
      </div>
      {/* Tentang Kami */}
      <div className="md:w-10/12 w-11/12 md:flex-row flex-col pt-16 mx-auto flex justify-center items-center">
        <div
          data-aos="fade-left"
          className="flex-1 md:order-2 flex justify-center"
        >
          <img
            src={Aboutusimg}
            alt="Tentang Kami"
            className="object-cover md:w-9/12 w-8/12"
          />
        </div>
        <div data-aos="fade-right" className="flex-1 md:order-1 md:mt-0 mt-11">
          <p className="text-5xl font-bold text-blue-950 tracking-wide mt-3">
            {t('Page Home Visi Misi Title')}
          </p>
          <p className="text-lg text-blue-950 tracking-wide mt-5 text-justify">
            {t('Page Home Visi Misi')}
          </p>
        </div>
      </div>

      {/* Teams*/}
      <p
        data-aos="fade-up"
        className="text-5xl font-bold pt-16 md:ml-0 lg:ml-0 ml-0 justify-start md:text-center lg:text-center text-center text-blue-950 mb-8 mt-0 "
      >
        {t('Page Home Tim Title')}
      </p>
      <div className="flex flex-col md:flex-row md:flex-wrap justify-center items-center space-y-4 md:space-x-4 mx-auto">
        {/* Card Team 1 */}
        <div
          data-aos="flip-left"
          className="pt-4 flex flex-col overflow-hidden rounded-lg cursor-pointer h-90 w-80 lg:w-80 md:w-80 m-0"
        >
          <img
            alt="profil"
            src={Minialif}
            className="relative z-10 mx-auto object-cover rounded-lg h-40 w-40  border-2 border-amber-400 -mb-20"
          />
          <div className="py-4 pt-24 bg-blue-950 rounded-lg shadow-lg z-0 border-blue-950 border-2">
            <div className="text-center">
              <p className="text-md text-amber-400 font-semibold">
                ALIF ABDURRAHMAN, S.H.,M.Kn.C.L.A.
              </p>
              <p className="text-md font-light text-white ">Managing partner</p>
            </div>
          </div>
        </div>


        {/* Card Team 2 */}
        <div
          data-aos="flip-left"
          className="flex flex-col overflow-hidden rounded-lg cursor-pointer h-90  w-80 lg:w-80 md:w-80 m-3"
        >
          <img
            alt="profil"
            src={Minicerry}
            className="relative z-10 mx-auto object-cover rounded-lg h-40 w-40  border-2 border-amber-400 -mb-20"
          />
          <div className="py-4 pt-24 bg-blue-950 rounded-lg shadow-lg z-0 border-blue-950 border-2">
            <div className="text-center">
              <p className="text-md text-amber-400 font-semibold ">
                CERRY ABDULLAH, S.H.
              </p>
              <p className="text-md font-light text-white ">Partner</p>
            </div>
          </div>
        </div>

        {/* Card Team 3 */}
        <div
          data-aos="flip-left"
          className="flex flex-col overflow-hidden rounded-lg cursor-pointer h-90  w-80 lg:w-80 md:w-80 m-3"
        >
          <img
            alt="profil"
            src={Minierdi}
            className="relative z-10 mx-auto object-cover rounded-lg h-40 w-40  border-2 border-amber-400 -mb-20"
          />
          <div className="py-4 pt-24 bg-blue-950 rounded-lg shadow-lg z-0 border-blue-950 border-2">
            <div className="text-center">
              <p className="text-md text-amber-400 font-semibold">
                ERDI PURNOMO, S.H.
              </p>
              <p className="text-md font-light text-white ">Associate</p>
            </div>
          </div>
        </div>

        {/* Card Team 4 */}
        <div
          data-aos="flip-left"
          className="flex flex-col overflow-hidden rounded-lg cursor-pointer h-90  w-80 lg:w-80 md:w-80 m-3"
        >
          <img
            alt="profil"
            src={Miniagung}
            className="relative z-10 mx-auto object-cover rounded-lg h-40 w-40  border-2 border-amber-400 -mb-20"
          />
          <div className="py-4 pt-24 bg-blue-950 rounded-lg shadow-lg z-0 border-blue-950 border-2">
            <div className="text-center">
              <p className="text-md text-amber-400 font-semibold">
                AGUNG PRASETYA ADI, S.H.
              </p>
              <p className="text-md font-light text-white ">Junior associate</p>
            </div>
          </div>
        </div>

        {/* Card Team 5 */}
        <div
          data-aos="flip-left"
          className="flex flex-col overflow-hidden rounded-lg cursor-pointer h-90  w-80 lg:w-80 md:w-80 m-3"
        >
          <img
            alt="profil"
            src={Minitrinov}
            className="relative z-10 mx-auto object-cover rounded-lg h-40 w-40  border-2 border-amber-400 -mb-20"
          />
          <div className="py-4 pt-24 bg-blue-950 rounded-lg shadow-lg z-0 border-blue-950 border-2">
            <div className="text-center">
              <p className="text-md text-amber-400 font-semibold ">
                TRI NOVIAN BURMAN, S.H.
              </p>
              <p className="text-md font-light text-white ">Junior associate</p>
            </div>
          </div>
        </div>
      </div>


      <div className="justify-center flex pt-10">
        <Link
          data-aos="fade-up"
          className="bg-blue-950 ml-10 px-8 py-2 text-xl rounded-xl text-amber-400 hover:text-amber-400 font-medium inline-block mb-24 lg:mb-24 lg:-mt-0 md:-mt-0 hover:bg-blue-900 hover:shadow-md"
          to="/about"
        >
          {t('Page Home Button Tim Selengkapnya')}
        </Link>
      </div>

      {/* Article */}
      <p data-aos="fade-up" className="text-5xl font-bold pt-16 md:ml-10 lg:ml-10 ml-0 justify-start md:text-center lg:text-center text-center text-blue-950 mb-8 -mt-20 ">
        {t('Page Home Article Title')}</p>
      <div data-aos="fade-up" className="flex flex-wrap justify-between w-11/12 mx-auto">
        {keyArticle != []
          ? keyArticle.map((key) => {
            return (
              <BlogCard
                key={key}
                title={dataArt[key].title}
                tag={dataArt[key].tag}
                imgArt={dataArt[key].image}
                desc={dataArt[key].desc}
                dateTime={key}
              />
            );
          })
          : null}
      </div>

      {/* Alamat Maps Perusahaan */}
      <h3 data-aos="fade-up" className="text-center text-5xl font-bold text-blue-950 mt-32 ">
        {t('Page Home Visit Us')}
      </h3>
      <div data-aos="fade-up" className="md:w-8/12 w-11/12 mx-auto">
        <div>
          <iframe
            className="mt-8 rounded-xl filter shadow-lg mb-8"
            width="100%"
            height="400"
            frameBorder="0"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.763657632559!2d110.4656898738317!3d-7.0370396689448125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e708b9bca9072c1%3A0x6e38c32b8668729c!2sLaw%20Firm%20Abdurrahman%20%26%20Co!5e0!3m2!1sen!2sid!4v1694421283900!5m2!1sen!2sid"
          ></iframe>
        </div>
      </div>

      {/* CTA Whatsapp */}
      <div className="bg-white lg:mx-44  lg:-mt-20 -mt-16">
        <div className="lg:flex lg:items-center lg:justify-between flex w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
          <h2 data-aos="fade-right" className="lg:text-5xl md:text-4xl md:ml-32 lg:ml-0 ml-32 md:mt-1 text-2xl font-medium text-blue-950 sm:text-4xl">
            <span className="block font-bold">
              {t('Page Home Contact Us')}
            </span>
          </h2>
          {/* Icon CTA Small */}
          <div
            data-aos="fade-right"
            className="md:flex lg:flex justify-end gap-3 md:ml-32 md:-mt-2 hidden "
          >
            <a
              className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
              target="_blank"
              href="https://www.instagram.com/abdurrahman.lawfirm/"
            >
              <BsInstagram className="w-full h-full" />
            </a>
            <a
              className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
              target="_blank"
              href="mailto:abdurrahman.and.co@gmail.com"
            >
              <BiLogoGmail className="w-full h-full" />
            </a>
            <a
              className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
              target="_blank"
              href="https://wa.me/6282210100098"
            >
              <BsWhatsapp className="w-full h-full" />
            </a>
            <a
              className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
              target="_blank"
              href="https://www.linkedin.com/company/abdurrahman-co/"
            >
              <BiLogoLinkedin className="w-full h-full" />
            </a>
          </div>
        </div>
        {/* Icon CTA Small */}
        <div
          data-aos="fade-right"
          className="flex justify-end gap-3 -mt-10 mr-28 pb-5 md:hidden lg:hidden "
        >
          <a
            className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
            target="_blank"
            href="https://www.instagram.com/abdurrahman.lawfirm/"
          >
            <BsInstagram className="w-full h-full" />
          </a>
          <a
            className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
            target="_blank"
            href="mailto:abdurrahman.and.co@gmail.com"
          >
            <BiLogoGmail className="w-full h-full" />
          </a>
          <a
            className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
            target="_blank"
            href="https://wa.me/6282210100098"
          >
            <BsWhatsapp className="w-full h-full" />
          </a>
          <a
            className="mt-3 text-white w-10 h-10 p-2.5 bg-blue-950 rounded-full inline-block hover:text-white hover:bg-blue-900"
            target="_blank"
            href="https://www.linkedin.com/company/abdurrahman-co/"
          >
            <BiLogoLinkedin className="w-full h-full" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Home;
