import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import {Home, Aboutus, Service, Portofolio, Contactus, Lawyers, Blog, Editor, Article, Admin, EditorAdmin, Login, SignUp} from "./pages"
import {Footer, Navigation} from "./components"
import PortoDetail from './components/portoDetail';
import { FirebaseProvider } from './FirebaseContext';
import PrivateRoute from './components/PrivateRoute';

function App() {

  return (
    <FirebaseProvider className='App bg-slate-50'>
    <BrowserRouter>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/about" element={<Aboutus />}></Route>
        <Route path="/service" element={<Service />}></Route>
        <Route path="/portofolio" element={<Portofolio />}></Route>
        <Route path="/portofolio/:id" element={<PortoDetail />}></Route>
        <Route path="/review" element={<Contactus />}></Route>
        <Route path="/lawyers" element={<Lawyers />}></Route>
        <Route path="/blog" element={<Blog />}></Route>
        <Route path="/contact" element={<Contactus />}></Route>
        <Route path="/article/:id" element={<Article />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route element={<PrivateRoute />} >
          <Route path="/editor" element={<Editor />}></Route>
          <Route path="/admin" element={<Admin />}></Route>
          <Route path="/editor/:id" element={<EditorAdmin/>}></Route>
        </Route>
      </Routes>
      <Footer />
  </BrowserRouter>
  </FirebaseProvider>
  );
}

export default App;
